.video-container {
  background-color: #191919;
  width: 100%;
  height: 1000px;
  outline: none;
}

.video-js video:focus {
  outline: none;
}

.vjs-play-control:focus {
  outline: none;
}

.vjs-mute-control:focus {
  outline: none;
}

.vjs-picture-in-picture-control:focus {
  outline: none;
}

.vjs-fullscreen-control:focus {
  outline: none;
}
