.profile-page-container {
  align-items: center;
  justify-content: center;
  height: 90vh;
  color: #4a4a4a;
  font-family: "Helvetica";
  .container {
    max-width: 1200px !important;
    .profile-container {
      padding: 3rem 6.25rem;
    }
  }
  .content {
    padding: 30px;
  }
  .titles {
    h1 {
      font-size: 1.75rem;
      font-weight: 600;
    }
    .subtitle {
      font-size: 22px;
      line-height: 1.25;
    }
  }
  .video-wrapper {
    position: relative;
    overflow: hidden;
    width: 360px;
    max-width: 100%;
    max-height: 640px;
    max-height: 100%;
    border-radius: 8px;
    video {
      height: auto;
      max-width: 100%;
    }
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease;
      svg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 40px;
        margin: 2rem;
        color: #fff;
      }
      .watermark {
        position: absolute;
        top: 0;
        left: 0;
        height: 28px;
        margin: calc(2rem + 3px) calc(2rem + 4px);
        opacity: 1;
        transition: opacity 0.3s ease;
        &.active {
          opacity: 0;
        }
      }
      &.active {
        background-color: transparent;
      }
    }
  }
  .text-content {
    .titles {
      position: relative;
      .subtitle {
        font-size: 18px;
        margin-bottom: 1.5rem;
      }
      &::after {
        position: absolute;
        content: "";
        width: 174px;
        height: 2px;
        background-color: #dadada;
      }
      .btn-wrapper {
        .btn {
          border: none;
          transition: 0.5s;
          font-weight: 700;
          background-size: 150% auto;
          border-radius: 5px;
          color: #fff;
          width: 280px;
          &:hover {
            background-position: right center; /* change the direction of the change here */
          }
          &:focus {
            outline: 0;
          }
        }
        .btn-video {
          background-image: linear-gradient(
            to right,
            #7b81ec 0%,
            #a5aafa 51%,
            #7b81ec 100%
          );
          margin-bottom: 1rem;
        }
        .btn-audio {
          background-image: linear-gradient(
            to right,
            #fa80d2 0%,
            #fdace2 51%,
            #fa80d2 100%
          );
        }
        .btn-support {
          background-image: linear-gradient(
            to right,
            #ff8008 0%,
            #ffc837 51%,
            #ff8008 100%
          );
          margin-bottom: 1rem;
        }
        .btn {
          &:hover {
            background-position: right center;
          }
        }
      }
    }

    .about-text {
      max-height: 500px;
      overflow-y: scroll;
      p {
        margin-bottom: 1rem;
      }
      .show-loading-animation {
        .text-row {
          border-radius: 10px;
        }
      }
    }
    .about-text::-webkit-scrollbar {
      padding-top: 1rem;
      max-width: 8px;
      max-height: 8px;
    }
    .about-text::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.4);
      box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.4);
    }

    .services-box {
      margin-top: 1rem;
      h5 {
        font-size: 16px;
        font-weight: 600;
      }
      .btn-wrapper {
        .btn {
          border: none;
          transition: 0.5s;
          font-weight: 700;
          background-size: 150% auto;
          border-radius: 5px;
          color: #fff;
          width: 280px;
          &:hover {
            background-position: right center; /* change the direction of the change here */
          }
          &:focus {
            outline: 0;
          }
        }
        .btn-video {
          background-image: linear-gradient(
            to right,
            #7b81ec 0%,
            #a5aafa 51%,
            #7b81ec 100%
          );
          margin-bottom: 1rem;
        }
        .btn-audio {
          background-image: linear-gradient(
            to right,
            #fa80d2 0%,
            #fdace2 51%,
            #fa80d2 100%
          );
        }
        .btn-support {
          background-image: linear-gradient(
            to right,
            #ff8008 0%,
            #ffc837 51%,
            #ff8008 100%
          );
          margin-bottom: 1rem;
        }
        .btn {
          &:hover {
            background-position: right center;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .content {
      padding: 0;
    }
    .container {
      .profile-container {
        padding: 2rem;
      }
    }
    .about-text {
      margin-top: 2rem !important;
    }
  }
  @media (max-width: 767px) {
    height: 100%;
    .container {
      box-shadow: none;
    }
    .titles {
      padding: 15px 0px;
    }
    .about-text {
      margin-top: 0rem !important;
      max-height: 100% !important;
    }
    .video-wrapper {
      width: 100%;
      height: 375px;
      padding: 0;
      margin: 0;
      border-radius: 0;
      video {
        margin-top: -132px;
      }
      &.full-screen {
        height: auto;
        video {
          margin-top: 0;
        }
      }
    }
    .container {
      .profile-container {
        padding: 0;
      }
    }
  }
}

.creator-header-container {
  .header-content {
    .titles {
      position: relative;
      h1 {
        font-size: 1.75rem;
        font-weight: 600;
      }
      .subtitle {
        font-size: 18px;
      }
      .weblink {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .btn {
      border: none;
      transition: 0.5s;
      font-weight: 700;
      background-size: 150% auto;
      border-radius: 5px;
      color: #fff;
      width: 280px;
      &:hover {
        background-position: right center; /* change the direction of the change here */
      }
      &:focus {
        outline: 0;
      }
    }
    .btn-video {
      background-image: linear-gradient(
        to right,
        #7b81ec 0%,
        #a5aafa 51%,
        #7b81ec 100%
      );
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .btn-support {
      background-image: linear-gradient(
        to right,
        #ff8008 0%,
        #ffc837 51%,
        #ff8008 100%
      );
      margin-bottom: 1rem;
    }
  }
}

.no-creator-page-container {
  height: 80vh;
}
