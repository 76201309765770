.newAvatarFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.newAvatarFile + label {
  display: inline-block;
}

.newAvatarFile + label {
  cursor: pointer; /* "hand" cursor */
}

.newAvatarFile:focus + label {
  outline: -webkit-focus-ring-color auto 5px;
}

.newAvatarFile + label * {
  pointer-events: none;
}

.profile-page-locked-container {
  height: 70vh;
}
