@import "~video-react/styles/scss/video-react";

.video-react-video {
  border-radius: 5px;
  &:focus {
    outline: none;
  }
}

.video-react-control-bar {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
